import {
  DataFrame,
  Field,
  FieldConfig,
  FieldType,
  PanelData,
  Vector,
  dateTimeFormat,
  GrafanaTheme2,
} from '@grafana/data';
import { LineInterpolation } from '@grafana/ui';
import {
  EChartsOption,
  XAXisComponentOption,
  GridComponentOption,
  SeriesOption,
  YAXisComponentOption,
  // ECharts,
  DatasetComponentOption,
  DataZoomComponentOption,
  GraphicComponentOption,
  LineSeriesOption,
  CustomSeriesOption,
  ECharts,
} from 'echarts';

import { LogPlotOptions } from './types';
import { LogPlotFieldConfig } from './module';
import { ActivitySeriesView } from './components/ActivitySeries';
import { renderGeologyIntervals } from './components/MuglogSeries';
import { lithImages, qualifierDic } from '../../lib/LithologyImages';
import { getTemplateSrv } from '@grafana/runtime';
import _, { isNumber } from 'lodash';

//#region Constants
const X_AXIS_SPACING = 40;
const WELLNAME_GRID_HEIGHT = 40;
const DATA_ZOOM_WIDTH = 2;
var YAXIS_WIDTH = 5;
const DEFAULT_AXIS_OPTION = {
  axisTick: {
    show: false,
  },
  axisLine: {
    show: false,
  },
  axisLabel: {
    color: 'rgba(128, 128, 128, .9)',
  },
  splitLine: {
    lineStyle: {
      // color: 'rgba(128, 128, 128, .2)',
      // color: 'red',
    },
    show: true,
  },
};

let COLOR_PALETTE: string[] = [];

//#endregion
export interface WellView {
  data: DataFrame[];
  tracks: TrackView[];
  wellName: string;
  index: number;
  range: Range;
  dataZoom?: DataZoomComponentOption[];
  // dataZoomSlider?: DataZoomComponentOption;
  // dataZoomInsider?: DataZoomComponentOption;
  gridsLeft: number;
  gridsWidth: number;
  gridsTop: number;

  yAxisMax: number;
  yAxisMin: number;
}
export type TrackView = {
  well: WellView;
  //fields in the track
  fields: Array<Field<any, Vector<any>>>;
  //series in the track
  series: SeriesOption[];
  indexInWellView: number;
  indexInGrid: number;
  indexInGridHeader: number;
  yAxis?: YAXisComponentOption;
  xAxis?: XAXisComponentOption[];
};
export type LithologyTrackView = TrackView & {
  //if this is a lithology track
  lithologyCodes: Set<string>;
  lithologySeries: SeriesOption[];
  gIntervalHeaderGraphics?: GraphicComponentOption;
};

export type Range = { from: number; to: number; wellName: string };

export default class MultiWellLogplotOptions {
  //   protected yMax: number = 0;
  //   protected yMin: number = 0;
  //   protected tracksArr: number[] = [];
  protected logPlotOptions: Partial<LogPlotOptions> = {};
  protected echartInst!: ECharts;
  protected wells: { [key: string]: WellView } = {};
  protected grid: GridComponentOption[] = [];
  protected headerGrids: GridComponentOption[] = [];
  //   protected tracksArr: number[] = [];
  protected headerGridHeight = 0;
  protected xAxis: XAXisComponentOption[] = [];
  protected yAxis: YAXisComponentOption[] = [];
  protected yMin?: number;
  protected yMax?: number;
  protected series: SeriesOption[] = [];
  // protected unitList: Array<string | undefined> = [];
  protected unitList: { [key: string]: string | undefined } = {};
  protected dataZoom: DataZoomComponentOption[] = [];
  protected graphic: GraphicComponentOption[] = [];
  protected gIntervalGraphics: GraphicComponentOption[] = [];
  // protected toolbox: ToolboxComponentOption;
  // protected dataZoomSlider?: DataZoomComponentOption;
  // protected dataZoomInsider?: DataZoomComponentOption;
  protected linkYAxes = false;
  // protected dummyYAxis?: YAXisComponentOption;
  protected data?: PanelData;
  protected isDepth = false;
  // protected testLithCodes = [];
  // protected testLithCodes = ['LST', 'CEMENT', 'LST1', 'CLST1', 'SHALE', 'SND', 'ANH'];
  // protected lithologySeries: SeriesOption[] = [];

  protected theme: GrafanaTheme2;
  /**
   *
   */
  constructor(theme: GrafanaTheme2) {
    this.theme = theme;
    COLOR_PALETTE = [
      theme.colors.text.primary,
      '#5470c6',
      '#91cc75',
      '#fac858',
      '#ee6666',
      '#73c0de',
      '#3ba272',
      '#fc8452',
      '#9a60b4',
      '#ea7ccc',
      'Brown',
      'MidnightBlue',
      'Cyan',
      'Green',
      'Red',
      'Purple',
      'DeepPink',
      'Yellow',
      'Blue',
      'Orange',
    ];
  }
  setData(data: PanelData, update = true): EChartsOption {
    this.data = data;

    // let lastIdx = 0;
    let datasets = data.series.map((s, i) => {
      // let lastIndexInDataset = this.wells[s.name!].yAxisMax!;
      // let lastIndexInDataset = 0;
      let dataset: DatasetComponentOption = {};
      let dimensions = s.fields.map((f, i) => {
        if (f.type === FieldType.time) {
          let d: { name: string; type: 'time' | 'ordinal' } = {
            name: f.name,
            type: 'time',
          };

          return d;
        } else if (f.type === FieldType.string) {
          let d: { name: string; type: 'time' | 'ordinal' } = {
            name: f.name,
            type: 'ordinal',
          };
          return d;
        } else {
          //else if (f.type === FieldType.other) {
          let d: { name: string; type: 'time' | 'ordinal' } = { name: f.name, type: 'ordinal' };
          return d;
        }
      });

      let columar = s.fields.map((f) => f.values.toArray());
      dataset.dimensions = dimensions;
      dataset.source = columar;

      // if (i === 0) {
      //   lastIndexInDataset = columar[0][columar[0].length - 1];
      // }
      //
      dataset.name = s.name;
      return dataset;
    });

    let option: EChartsOption = { dataset: datasets };
    // return option;

    //update YAxis of the well
    // let yAxisUpdate = [];

    let maxIndexesPerWell = Object.values(this.wells).map((w) => {
      let lastIndex = datasets
        .filter((d) => d.name === w.wellName)
        .map((d) => {
          let indexes = (d.source as any)[0];

          //in some cases, the dataset is empty (because the log has no data)
          return indexes.length === 0 ? 0 : (indexes[indexes.length - 1] as number);
          // return indexes[indexes.length - 1] as number;
        });

      return Math.max(...lastIndex);
    });
    let maxLastIndex = Math.max(...maxIndexesPerWell);

    // console.info('maxIndexesPerWell', maxIndexesPerWell);
    if (maxLastIndex > this.yMax!) {
      // let offset = maxLastIndex - this.yMax!;
      this.yMin = this.yMin! + maxLastIndex - this.yMax!;
      this.yMax = maxLastIndex;
      option.yAxis = this.yAxis.map((y) => {
        return { min: this.yMin, max: this.yMax };
      });
      console.log('moving Y axis');
      console.log({ min: this.yMin, max: this.yMax });
    }

    //any new graphic elements (formation markers, or lithology track headers etc..)
    let newLithologySeriesOptions = this.prepareLithologySeries(data);
    if (newLithologySeriesOptions.series.length > 0) {
      console.info('newLithologySeriesOptions', newLithologySeriesOptions);
      option.series = newLithologySeriesOptions.series;
    }
    let gInterval = newLithologySeriesOptions.graphics;
    if (gInterval.length > 0) {
      let gToAdd: GraphicComponentOption[] = [];
      gInterval.forEach((g) => {
        let gExist = this.gIntervalGraphics.find((g2) => g2.id === g.id);
        if (!gExist) {
          gToAdd.push(g);
        }
      });
      this.gIntervalGraphics.push(...gToAdd);
      if (gToAdd.length > 0) {
        option.graphic = gToAdd;
      }
    }

    if (update) {
      this.echartInst?.setOption(option);
    }
    return option;
  }

  setYMinMax(yMin: number, yMax: number) {
    if (yMin < this.yMin! || yMax > this.yMax!) {
      this.yMin = Math.min(this.yMin!, yMin);
      this.yMax = Math.max(this.yMax!, yMax);
      return {
        yAxis: this.yAxis.map((y) => {
          return { min: this.yMin, max: this.yMax };
        }),
      };
    } else {
      return undefined;
    }
  }

  resize() { }
  prepareOption(
    logPlotOptions: LogPlotOptions,
    data: PanelData,
    indexRange: Range[],
    isDepth: boolean,
    echartInst: ECharts
  ): EChartsOption {
    this.logPlotOptions = logPlotOptions;
    this.echartInst = echartInst;
    this.isDepth = isDepth;
    //reset to default, when prepare option is called multiple times!
    this.gIntervalGraphics = [];
    this.graphic = [];

    this.initWells(logPlotOptions, data, indexRange);
    this.initGrid(logPlotOptions, data);
    this.initXAxis();
    this.initYAxis(logPlotOptions, indexRange);
    // this.initGeologyIntervalHeader();
    this.prepareSeries(data);
    this.initDataZoom();

    let option: EChartsOption = {
      title: { show: false },
      legend: { show: false },
      animation: false,
      // backgroundColor: 'transparent',
      tooltip: {
        trigger: 'axis',
        formatter: (param: any) => this.tooltipFormatter(param, this.unitList),
        axisPointer: logPlotOptions.depthLog
          ? {
            type: 'none',
          }
          : undefined,
      },
      axisPointer: {
        // label: {
        //   show: true,
        //   backgroundColor: 'true',
        // },
      },
      grid: this.grid,
      yAxis: this.yAxis,
      xAxis: this.xAxis,
      graphic: this.graphic,

      dataZoom: this.dataZoom,

      useUTC: false,
      series: this.series,
    };

    console.log('======= calling prepareOption=========');
    console.log(option);
    console.log(this);
    // echartInst.setOption(option, true, true);
    echartInst.setOption(option, true);
    return option;
  }

  protected initWells(options: LogPlotOptions, data: PanelData, indexRange: Range[]) {
    this.wells = data.series.reduce((accumulator, current, seriesIndex) => {
      if (!accumulator[current.name!]) {
        let range = indexRange.find((r) => r.wellName === current.name!)!;
        let wellview: WellView = {
          data: [],
          wellName: current.name!,
          index: 0,
          tracks: [],
          range: range,
          yAxisMax: range.to,
          yAxisMin: range.from,
          //these initial values will be set later when grids are created
          gridsLeft: 0,
          gridsTop: 0,
          gridsWidth: 0,
        };

        let tracks = Array.from(Array(options.tracks).keys()).map((i) => {
          let tr: LithologyTrackView = {
            indexInWellView: i,
            fields: [],
            series: [],
            well: wellview,
            //these values will be set on grid creation later
            indexInGrid: 0,
            indexInGridHeader: 0,

            //lithology
            lithologyCodes: new Set<string>(),
            gIntervalHeaderGraphics: undefined,
            lithologySeries: [],
          };
          return tr;
        });
        wellview.tracks = tracks;
        if (tracks.length === 1) {
          YAXIS_WIDTH = 20;
        }
        accumulator[current.name!] = wellview;
      }

      accumulator[current.name!].data.push(current);
      current.fields.map((f) => {
        let c = f.config as FieldConfig<LogPlotFieldConfig>;
        //skip time and depth index fields
        if (f.name === 'time' || f.name === 'depth') {
          return;
        }
        (f as any).seriesIndex = seriesIndex;
        let trackId = c.custom?.trackId === undefined ? 0 : c.custom?.trackId;
        accumulator[current.name!].tracks[trackId].fields.push(f);
      });

      return accumulator;
    }, {} as { [key: string]: WellView });

    //set index
    Object.keys(this.wells).forEach((wellname, index) => (this.wells[wellname].index = index));

    console.log('wells====');
    console.log(this.wells);
    // //set tracks
    // Object.values(this.wells).map((w) => {
    //   w.data.map((d) => {
    //     d.fields;
    //   });
    // });
  }

  protected initGrid(options: LogPlotOptions, data: PanelData) {
    let numberOfWells = Object.keys(this.wells).length;

    // let multiWell = Object.keys(this.wells).length > 1;
    let wellNameGridHeight = numberOfWells > 1 ? WELLNAME_GRID_HEIGHT : 0;
    //reset to default value
    this.grid = [];

    let maxFieldsInTrack = Object.values(this.wells)
      .flatMap((w) => w.tracks)
      .reduce((count, tCurrent) => {
        return Math.max(count, tCurrent.fields.length);
      }, 0);

    this.headerGridHeight = maxFieldsInTrack * X_AXIS_SPACING + 20;
    let gridWidth = (100 / numberOfWells - YAXIS_WIDTH - DATA_ZOOM_WIDTH) / options.tracks;

    let gridOptionsByWell = Object.values(this.wells).map((w, wellIndex) => {
      let gridsInWell = w.tracks.map((t, trackIndex) => {
        let horizontalIndex = wellIndex * options.tracks + trackIndex;
        let grid: GridComponentOption = {
          id: `grid_${w.wellName}_${t.indexInWellView}`,
          width: `${gridWidth}%`,
          left: `${YAXIS_WIDTH * (wellIndex + 1) + gridWidth * horizontalIndex}%`,
          top: this.headerGridHeight + wellNameGridHeight,
          bottom: 100,
          borderColor: this.theme.colors.text.primary,
        };
        t.indexInGrid = wellIndex * options.tracks + t.indexInWellView;
        return grid;
      });
      w.gridsLeft = YAXIS_WIDTH * (wellIndex + 1) + gridWidth * options.tracks * wellIndex;
      w.gridsWidth = gridWidth * options.tracks;
      w.gridsTop = this.headerGridHeight + wellNameGridHeight;

      return gridsInWell;
    });
    this.grid = gridOptionsByWell.flat();

    //set headerindex in tracks
    let tracksNumber = this.grid.length;

    Object.values(this.wells)
      .flatMap((w) => w.tracks)
      .forEach((t) => (t.indexInGridHeader = t.indexInGrid + tracksNumber));
    //header grids, 图头框框
    let headerGrids = this.grid.map((g) => {
      let header: GridComponentOption = {
        show: true,
        id: g.id + '_header',
        borderColor: this.theme.colors.text.primary,
        borderWidth: 2,
        top: wellNameGridHeight,
        height: this.headerGridHeight,
        left: g.left,

        width: g.width,
      };
      return header;
    });

    this.headerGrids = [];
    this.headerGrids.push(...headerGrids);
    this.grid.push(...headerGrids);

    if (numberOfWells > 1) {
      this.graphic = [];
      let wellNameGrid = Object.values(this.wells).map((w, wellIndex) => {
        let gridWidth = 100 / numberOfWells - YAXIS_WIDTH - DATA_ZOOM_WIDTH;
        let left = wellIndex * gridWidth + YAXIS_WIDTH * (wellIndex + 1);
        let grid: GridComponentOption = {
          width: `${gridWidth}%`,
          left: `${left}%`,
          top: 0,
          height: WELLNAME_GRID_HEIGHT,
          borderWidth: 2,
          borderColor: this.theme.colors.text.primary,
          show: true,
        };

        this.graphic.push({
          id: `g_welltitile_${w.wellName}`,
          type: 'rect',
          left: `${left + gridWidth / 2}%`,
          top: 0,
          textConfig: {
            position: 'inside',
          },
          textContent: {
            style: {
              text: w.wellName,
              fontSize: 25,
              fill: '#000',
            },
          },
          shape: {
            height: WELLNAME_GRID_HEIGHT,
          },
        });
        return grid;
      });
      this.grid.push(...wellNameGrid);
    }
  }

  protected getXIndexId = (wellname: string, fieldName: string) => `xAxis_${wellname}_${fieldName}`;
  protected getYIndexId = (t: TrackView) => `yAxis_${t.well.wellName}_${t.indexInWellView}`;

  protected initXAxis() {
    //go 3 levels deep from well->track->field to build XAxis
    let xAxisArr = Object.values(this.wells).map((w) => {
      return w.tracks.map((t, ti) => {
        let xAxesInTrack = t.fields.map((f, i) => {
          // if (f.name === 'time' || f.name === 'depth') {
          //   return {};
          // }
          let config = f.config as FieldConfig<LogPlotFieldConfig>;
          let unit = f.display ? f.display(0).suffix?.trim() : config.unit;
          unit = unit ? ` (${unit})` : '';
          let color = this.getColorPalette(f);

          let axis: XAXisComponentOption = {
            // id: `xAxis_${w.wellName}_${f.name}`,
            id: this.getXIndexId(w.wellName, f.name),
            ...DEFAULT_AXIS_OPTION,
            // type: config.custom?.scaleDistribution?.type === ScaleDistribution.Logarithmic ? 'log' : 'value',
            type: 'value',
            position: 'top',
            offset: i * X_AXIS_SPACING,
            name: f.name + unit,
            nameLocation: 'middle',
            axisPointer: {
              show: false,
            },
            // min: config.min || undefined,
            min: config.min || 0,
            max: config.max || undefined,
            gridIndex: t.indexInGrid,
            splitLine: {
              //   show: index === 0,
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color,
                width: 2,
              },
            },
            // minInterval: Number.MAX_VALUE,
            splitNumber: 0.1,
            nameTextStyle: { align: 'center', color, fontSize: config.custom?.fontSize ?? 14 },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: (value: any, i: number) => {
                if (i === 0) {
                  //add some padding to the left of min label
                  return value;
                } else {
                  return value + '      ';
                }
              },
              // formatter: (value: number, i: number) => '',
              color,
              align: 'right',
              padding: [0, -15, 0, 0],
              showMaxLabel: true,
              showMinLabel: true,
              textBorderWidth: 1,
              // fontSize: 20,
              textBorderColor: color,
            },
          };

          return axis;
        });
        t.xAxis = xAxesInTrack;
        return xAxesInTrack;
      });
    });

    this.xAxis = xAxisArr.flat(2);
    //add invisible axis
    Object.values(this.wells).map((w) => {
      return w.tracks.map((t) => {
        this.xAxis.push({
          ...DEFAULT_AXIS_OPTION,
          gridIndex: t.indexInGrid,
          min: 0,
          max: 5,
          show: true,
          splitLine: {
            show: this.logPlotOptions.hideGridlinesOnTrack !== t.indexInGrid,
            interval: 1,
          },
          axisLabel: {
            show: false,
          },
          type: 'value',
        });
      });
    });
  }

  protected initYAxis(options: LogPlotOptions, indexRange: Range[]) {
    let interval = this.getZoomMaxValueInterval();
    this.yMin = Math.min(...indexRange.map((r) => r.from));
    this.yMax = Math.max(...indexRange.map((r) => r.to));
    //go 3 levels deep from well->track->field to build XAxis
    let yAxisArr = Object.values(this.wells).map((w) => {
      let yAxisInTrack = w.tracks.map((t) => {
        let y: YAXisComponentOption = {
          //Becareful, this is not a deep clone
          ...DEFAULT_AXIS_OPTION,
          // id: `yAxis_${w.wellName}_${t.indexInWellView}`,
          id: this.getYIndexId(t),
          type: options.depthLog ? 'value' : 'time',
          axisPointer: {
            show: true,
          },
          // min: w.range.from,
          // max: w.range.to,
          min: this.yMin,
          max: this.yMax,
          //时间Y坐标轴无法设置45分钟间隔，已确认是Echarts  中的一个bug！！！！
          interval: options.depthLog ? undefined : interval! + 3000,
          // minInterval: interval!,
          minInterval: options.depthLog ? interval : interval! - 5000,

          maxInterval: options.depthLog ? interval : interval! + 5000,
          splitNumber: options.depthLog ? 10 : 1,
          // zlevel: Number.MAX_SAFE_INTEGER,
          //   max: Math.max(w.range.to, w.range.from + this.getZoomMaxValueInterval()!),
          gridIndex: t.indexInGrid,
          inverse: true,
          // splitLine: {
          //   show: true,
          //   // interval: 1,
          // },
          axisLine: {
            lineStyle: {
              width: 3,
            },
            show: true,
          },
          axisLabel: {
            // show: true,
            show: t.indexInWellView === 0,
            showMaxLabel: false,
            showMinLabel: false,

            // formatter: options.depthLog
            //   ? undefined
            //   : (val: number) => dateTimeFormat(val, { format: 'MM-DD[\n] HH:mm:ss' }),
            formatter: options.depthLog
              ? undefined
              : (val: number) => dateTimeFormat(val, { format: 'MM-DD[\n] HH:mm:ss' }),
          },
        };
        t.yAxis = y;
        return y;
      });

      return yAxisInTrack;
    });

    this.yAxis = yAxisArr.flat();
    //NOTE: dummy y Axis overlap with the grid!
    // //a dummy Y Axis just to show the scroll all scroll bar
    // this.dummyYAxis = {
    //   type: 'value',
    //   min: 0,
    //   max: 100,
    //   show: false,
    //   gridIndex: 0,
    // };
    // this.yAxis.push(this.dummyYAxis);
  }

  protected initGeologyIntervalHeader2(t: LithologyTrackView) {
    if (t.lithologyCodes === undefined || t.lithologyCodes.size === 0) {
      return;
    }
    const MAX_ITEMS_IN_ROW = 4;
    const ROW_HEIGHT = 60;
    let hg = this.grid[t.indexInGridHeader];
    let f = t.fields[0];
    if (f.name === 'Interpreted') {
      return;
    }

    console.info('initGeologyIntervalHeader2 Grid', hg);
    // this.echartInst.convertToPixel()

    let buildHeaderGraphics = (f: Field, hg: GridComponentOption, lithCodes: Set<string>) => {
      let width = (Number.parseFloat(hg.width!.toString().replace('%', '')) * this.echartInst.getWidth()) / 100;
      let spanTwoTracks = f.name === 'Cuttings';
      if (spanTwoTracks) {
        width = width * 2;
      }
      let itemWidth = width / (MAX_ITEMS_IN_ROW + 1);

      let hgLeftPadding =
        (Number.parseFloat(hg.width!.toString().replace('%', '')) * (spanTwoTracks ? 0.25 : 0.125)) /
        (MAX_ITEMS_IN_ROW + 1);
      let hgLeft = (Number.parseFloat(hg.left!.toString().replace('%', '')) + hgLeftPadding).toString() + '%';

      let children = Object.entries(lithImages)
        .filter((l) => lithCodes.has(l[0]))
        .map((lithImg, i) => {
          let row = Math.trunc(i / MAX_ITEMS_IN_ROW);
          let key = lithImg[0];
          let img = lithImg[1];
          let id = `${hg.id}_lithologyHeader_${key}`;
          let left = (i % MAX_ITEMS_IN_ROW) / MAX_ITEMS_IN_ROW;

          let imgCodeLith: GraphicComponentOption = {
            type: 'rect',
            id: id + '_img',
            left: `${left * 100}%`,
            z: 100,
            bottom: (row + 1) * ROW_HEIGHT - 20,
            shape: {
              width: itemWidth,
              height: 30,
            },
            textContent: {
              // style: { text: key, fill: 'white' },
              style: { text: key, fill: this.theme.colors.text.primary },
            },
            textConfig: { position: 'bottom' },
            style: {
              fill: {
                type: 'pattern',
                image: img,
                repeat: 'repeat' as any,
              } as any,
            },
          };

          return imgCodeLith;
        });

      console.info('inpixel', this.echartInst.convertToPixel({ gridId: hg.id }, 100));
      console.info('hgid', hg.id);

      let g: GraphicComponentOption = {
        type: 'group',
        id: hg.id + 'lithologyHeader',
        // left: hg.left,
        left: hgLeft,
        width,
        // $action: 'replace',
        //width:hg.width,
        top: (hg.top as number) + 20,
        children,
      };

      return g;
    };
    let g = buildHeaderGraphics(f!, hg, t.lithologyCodes);
    t.gIntervalHeaderGraphics = g;
    return g;
  }
  // protected initGeologyIntervalHeader(data: PanelData) {
  //   const MAX_ITEMS_IN_ROW = 4;
  //   const ROW_HEIGHT = 60;

  //   let buildHeaderGraphics = (f: Field, hg: GridComponentOption, lithCodes: Set<string>) => {
  //     let children = Object.entries(lithImages)
  //       .filter((l) => lithCodes.has(l[0]))
  //       .map((lithImg, i) => {
  //         let row = Math.trunc(i / MAX_ITEMS_IN_ROW);
  //         let key = lithImg[0];
  //         let img = lithImg[1];
  //         let children: GraphicComponentOption[] = [
  //           {
  //             type: 'image',
  //             // left: 'center',
  //             bottom: (row + 1) * ROW_HEIGHT - 20,
  //             style: {
  //               image: img,
  //             },
  //           },
  //           {
  //             type: 'text',
  //             // left: 'center',
  //             bottom: (row + 1) * ROW_HEIGHT - 40,
  //             style: {
  //               text: key,
  //             },
  //           },
  //         ];
  //         return children;
  //       });

  //     let g: GraphicComponentOption = {
  //       type: 'group',
  //       id: hg.id + 'lithologyHeader',
  //       left: hg.left,
  //       // $action: 'replace',
  //       //width:hg.width,
  //       top: hg.top,
  //       children: children.map((ch, i) => {
  //         return {
  //           type: 'group',
  //           // left: `${i * 15}%`,
  //           left: (i % MAX_ITEMS_IN_ROW) * 90,
  //           children: ch,
  //         } as any;
  //       }),
  //     };

  //     return g;
  //   };

  //   let gIntervalHeaderGraphics = Object.values(this.wells)
  //     .map((w) => {
  //       //find the track that contains lithology
  //       return w.tracks
  //         .filter((t) => t.fields.find((f) => f.name.indexOf('Cuttings') >= 0 || f.name.indexOf('Lithology') >= 0))
  //         .map((t) => {
  //           // let f = t.fields.find((f) => f.name.indexOf('Lithology') >= 0);
  //           let f = data.series
  //             .flatMap((s) => s.fields)
  //             .find((f) => f.name.indexOf('Cuttings') >= 0 || f.name.indexOf('Lithology') >= 0);
  //           console.debug('Lithology', f?.values.toArray());
  //           let lithCodes: Set<string> = new Set<string>();
  //           f?.values.toArray().forEach((v) => {
  //             v.lith.forEach((lith: any) => {
  //               lithCodes.add(lith.codeLith);
  //             });
  //           });
  //           console.debug('lithCodes', lithCodes);
  //           let hg = this.grid[t.indexInGridHeader];
  //           let g = buildHeaderGraphics(f!, hg, lithCodes);
  //           // debugger;
  //           // if (!this.graphic.find((g2) => g2.id === g.id)) {
  //           //   this.graphic.push(g);
  //           // }
  //           return g;
  //         });
  //     })
  //     .flat();
  //   return gIntervalHeaderGraphics;
  // }

  protected initDataZoom() {
    this.dataZoom = [];
    const getDataZoom = (
      namePostfix: string,
      yAxisIndex: number[],
      dataZoomLeft: string,
      startValue: number | undefined,
      endValue: number | undefined,
      disabled = false,
      zoomLock = false
    ) => {
      let rangeInPercent = ((endValue! - startValue!) / (this.yMax! - this.yMin!)) * 100;
      let zoomSlider: DataZoomComponentOption = {
        id: 'dataZoomYSlider' + namePostfix,
        type: 'slider',
        orient: 'vertical',
        disabled,
        brushSelect: false,
        filterMode: this.logPlotOptions.depthLog ? 'none' : 'filter', //temporary fix to mudlog filtering issue
        yAxisIndex,
        zoomLock,
        zoomOnMouseWheel: false,
        minValueSpan: this.getZoomMaxValueInterval()! * 10,
        showDetail: false,
        showDataShadow: false,
        left: dataZoomLeft + '%',
      };
      if (Object.keys(this.wells).length > 1) {
        //multi well
        zoomSlider.startValue = startValue;
        zoomSlider.endValue = endValue;
        // zoomSlider.rangeMode = ['value', 'value'];
      } else {
        zoomSlider.start = 100 - rangeInPercent;
        zoomSlider.end = 100;
        // zoomSlider.rangeMode = ['percent', 'percent'];
      }

      let zoomInsider: DataZoomComponentOption = {
        id: 'dataZoomYInSider' + namePostfix,
        show: !disabled,
        type: 'inside',
        filterMode: this.logPlotOptions.depthLog ? 'none' : 'filter', //temporary fix to mudlog filtering issue
        yAxisIndex,
        moveOnMouseWheel: true,
        zoomOnMouseWheel: false,
        moveOnMouseMove: false,
        zoomLock,
      };

      return [zoomSlider, zoomInsider];
    };

    Object.values(this.wells).map((w) => {
      let left = this.grid[w.tracks[0].indexInGrid].left as string;
      let width = this.grid[w.tracks[0].indexInGrid].width as string;
      let dataZoomLeft =
        Number.parseFloat(left.replace('%', '')) + w.tracks.length * Number.parseFloat(width.replace('%', ''));

      //find yAxisIndex
      let yAxisArr = w.tracks.map((t) => t.yAxis!);
      let yAxisIndexes = yAxisArr.map((y) => this.yAxis.indexOf(y));
      let startValue = this.getZoomMaxValueInterval() ? this.yMax! - this.getZoomMaxValueInterval()! * 10 : undefined; //10 is the number of Y split lines
      let endValue = this.yMax!;
      let [zoomSlider, zoomInsider] = getDataZoom(
        `_${w.wellName}`,
        yAxisIndexes,
        dataZoomLeft.toString() + '%',
        startValue,
        endValue
      );
      w.dataZoom = [zoomSlider, zoomInsider];
    });

    // this.dataZoom.push(...dataZoomArr.flat());
    this.dataZoom = [];
    return this.SetLinkYAxes(this.linkYAxes);
  }
  SetLinkYAxes(linkYAxes: boolean) {
    this.linkYAxes = linkYAxes;
    this.dataZoom = [];

    this.dataZoom.push(
      ...Object.values(this.wells)
        .map((w) => w.dataZoom!)
        .flat()
    );

    return { dataZoom: this.dataZoom };
  }

  protected prepareSeries(data: PanelData) {
    let index = 0;

    //clear old marklines
    // this.markLine = [];
    let seriesArr = Object.values(this.wells).map((w) => {
      return w.tracks.map((t) => {
        return t.fields.map((f, i) => {
          // this.unitList[f.name] = f.config.unit;
          this.unitList[f.name] = f.display ? f.display(0).suffix?.trim() : f.config.unit;

          let config = f.config as FieldConfig<LogPlotFieldConfig>;
          let color = this.getColorPalette(f);
          let lineStyleType: 'dashed' | 'dotted' | 'solid' | undefined;
          switch (config.custom?.lineStyle?.fill) {
            case 'dash':
              lineStyleType = 'dashed';
            case 'dot':
              lineStyleType = 'dotted';
            case 'solid':
              lineStyleType = 'solid';
            default:
              lineStyleType = undefined;
          }

          let s: SeriesOption | SeriesOption[];
          let commonSeriesOption = {
            id: `series_${w.wellName}_${f.name}`,
            name: f.name,
            yAxisIndex: t.indexInGrid,
            xAxisIndex: index,
            seriesLayoutBy: 'row',
            encode: {
              y: 0,
              x: [f.name],
            },
            silent: true,
            datasetIndex: (f as any).seriesIndex,
          } as SeriesOption;

          //different types of series
          if (f.type === FieldType.number) {
            s = {
              ...commonSeriesOption,
              type: 'line',
              showSymbol: false,
              areaStyle: config.custom?.fillOpacity
                ? {
                  opacity: config.custom?.fillOpacity ? config.custom!.fillOpacity! / 100 : 0,
                  color,
                  origin: 'auto',
                  // origin: 'end',
                }
                : undefined,
              lineStyle: {
                width: config.custom?.lineWidth,
                type: lineStyleType,

                color,
              },
              smooth: config.custom?.lineInterpolation === LineInterpolation.Smooth,
              connectNulls: config.custom?.spanNulls === true,
            } as LineSeriesOption;
          } else if (f.type === FieldType.string && f.name !== 'formationMarker') {
            let acv = new ActivitySeriesView(this.theme);
            s = {
              ...commonSeriesOption,
              type: 'custom',
              // renderItem: (params, api) => renderActivityItem(params, api, config.custom?.fontSize),
              renderItem: (params, api) => acv.renderActivityItem(params, api, config.custom?.fontSize),
              coordinateSystem: 'cartesian2d',
              clip: true,
              encode: {
                y: 0,
                x: [f.name],
                // tooltip: ['time1', f.name],
              },
              // datasetIndex: 1
              datasetIndex: (f as any).seriesIndex,
            } as CustomSeriesOption;
          } else if (f.type === FieldType.string && f.name === 'formationMarker') {
            s = [];
          } else if (f.type === FieldType.other) {
            s = [];
          }

          let sWithUnit: any = s!;
          // sWithUnit.unit = f.config.unit;
          sWithUnit.unit = f.display ? f.display(0).suffix?.trim() : f.config.unit;
          //increase index
          index++;
          return sWithUnit;
        });
      });
    });
    // });

    this.series = seriesArr.flat(3);
  }

  //this method returns any new lithology series option that should be added to EchartOptions
  protected prepareLithologySeries(data: PanelData) {
    let newOptions = Object.values(this.wells)
      .flatMap((w) => w.tracks)
      .map((t) => this.prepareLithologySeriesOfTrack(t, data))
      .filter((o) => o !== undefined);

    return { series: newOptions.flatMap((o) => o?.series!), graphics: newOptions.flatMap((o) => o?.graphics!) };
  }
  protected prepareLithologySeriesOfTrack(track: TrackView, data: PanelData) {
    // return [];

    //check if this is a lithology track!.
    if (track.fields.length !== 1) {
      return;
    }
    let f = track.fields[0];
    if (f.type !== FieldType.other || f.name === 'formationMarker') {
      return;
    }
    let t = track as LithologyTrackView;
    //init LithologyTrackView Properties
    if (t.lithologyCodes === undefined) {
      t.lithologyCodes = new Set<string>();
    }
    if (t.lithologySeries === undefined) {
      t.lithologySeries = [];
    }
    f = data.series
      .filter((s) => s.name === track.well.wellName)
      ?.flatMap((frame) => frame.fields)
      .find((f2) => f2.name === f.name)!;

    let lithCodesSet: Set<string> = new Set<string>();
    f?.values.toArray().forEach((v) => {
      v.lith.forEach((lith: any) => {
        lithCodesSet.add(lith.codeLith);
      });
    });
    let newLithCodesArr = Array.from(lithCodesSet);
    newLithCodesArr = newLithCodesArr.filter((l) => !t.lithologyCodes!.has(l));
    newLithCodesArr.forEach((l) => t.lithologyCodes?.add(l));

    if (f === undefined) {
      return;
    }
    let commonSeriesOption = {
      name: f.name,
      // yAxisIndex: t.indexInGrid,
      // xAxisId: xAxisId.toString(),
      xAxisIndex: this.xAxis.findIndex((x) => x.id === this.getXIndexId(t.well.wellName, f.name)),
      yAxisIndex: this.yAxis.findIndex((y) => y.id === this.getYIndexId(t)),
      // yAxisId: this.getYIndexId(t),
      seriesLayoutBy: 'row',
      encode: {
        y: 0,
        x: [f.name],
      },
      silent: true,
      datasetIndex: data.series.findIndex((s) => s.fields.find((f2) => f2 === f)),
    };
    // debugger;

    // s = Object.keys(lithImages)
    let newSeriesOption = newLithCodesArr.map((codeLith: string) => {
      return {
        ...commonSeriesOption,
        id: `series_${t.well.wellName}_${f.name}_${codeLith}`,
        type: 'custom',
        clip: true,
        renderItem: (params, api) => renderGeologyIntervals(params, api, codeLith),
        coordinateSystem: 'cartesian2d',
        itemStyle: {
          color: {
            image: (lithImages as any)[codeLith],
            repeat: 'repeat',
          },
        },
      } as SeriesOption;
    });
    t.lithologySeries?.push(...newSeriesOption);

    let graphics = this.initGeologyIntervalHeader2(t) ?? [];
    return { series: newSeriesOption, graphics };
  }

  //#region   utilities
  protected getColorPalette(f: Field) {
    // f.config;
    let index = Object.values(this.wells)
      .flatMap((w) => w.tracks)
      .flatMap((t) => t.fields)
      .indexOf(f);
    // let index = (f as any).seriesIndex as number;
    return f.config.color?.fixedColor || COLOR_PALETTE[index % (COLOR_PALETTE.length - 1)];
  }
  private _color_dic: string[] = [];
  protected getColorPalette2(s: string) {
    if (this._color_dic.indexOf(s) < 0) {
      this._color_dic.push(s);
    }
    return COLOR_PALETTE[this._color_dic.indexOf(s) % (COLOR_PALETTE.length - 1)];
  }

  protected tooltipFormatter(params: any, unitList: { [key: string]: string | undefined }) {
    // let p = params as EChartsOption.Tooltip.Format[];
    // console.log(params);
    let axisVal: any = null;
    let isLithology = false;
    // console.log(params);
    return params
      .sort((p: any) => p.axisIndex)
      .map((s: any) => {
        let append = '';
        if (axisVal !== s.axisValue) {
          axisVal = s.axisValue;
          if (s.axisType === 'yAxis.time') {
            // append = 'time: ' + s.axisValueLabel;
            append = 'time: ' + this.timeAxisLabelFormatter(s.axisValue);
            try {
              let md = s.data[s.dimensionNames.indexOf('md')];
              if (md) {
                append = append + ' md: ' + md;
              }
            } catch (ex) { }
          }
          if (s.axisType === 'yAxis.value') {
            append = 'depth: ' + s.axisValue;
          }
          append = `<h4>${append} </h4>`;
        }
        let dt = s.data[s.encode.x[0]];
        if (isLithology === true) {
          return '';
        }
        //this is lithology
        if (typeof dt === 'object') {
          isLithology = true;
          let txt = dt.lith
            .map((l: any) => {
              let qualifier = l.qualifier;
              if (qualifier && qualifier.length > 0) {
                qualifier = qualifier.map((q: any) => {
                  return q.type + `<img src=${(qualifierDic as any)[q.type]} style="width:32px;height:32px" />`;
                });
                qualifier = qualifier.join(',');
                qualifier = `(Qualifier: ${qualifier} )`;
                return `${l.codeLith} : ${l.lithPc}% ${qualifier}`;
              }
              return `${l.codeLith} : ${l.lithPc}% `;
            })
            .join(' ,');

          append = `<h4>MD Top: ${dt.mdTop}, MD Bottom: ${dt.mdBottom}</h4>`;
          dt = txt;
        }
        return `${append}${s.seriesName}: ${dt ?? '-'} ${unitList[s.seriesName] ?? ''}`;
      })
      .filter((line: string) => line !== '')
      .join('<br />');
  }

  protected timeAxisLabelFormatter(val: number) {
    return dateTimeFormat(val);
  }

  protected getZoomMaxValueInterval() {
    let templateSrv = getTemplateSrv();
    // let axisratio = '$axisratio';
    let axisratio = this.logPlotOptions.axisratio;

    let variableFound = false;



    //if variables are set, it overwrites 
    if (this.isDepth && templateSrv.getVariables().find((v) => v.name === 'axisratio_depth') !== undefined) {
      axisratio = '$axisratio_depth';
      variableFound = true;
    } else if (!this.isDepth && templateSrv.getVariables().find((v) => v.name === 'axisratio_time') !== undefined) {
      axisratio = '$axisratio_time';
      variableFound = true;
    } else if (templateSrv.getVariables().find((v) => v.name === 'axisratio') !== undefined) {
      axisratio = '$axisratio';
      variableFound = true;
    }


    let strVal = templateSrv.replace(axisratio);

    //default values
    if (!variableFound && axisratio && !isNumber( axisratio.charAt(0))) {
      if (this.isDepth) {
        axisratio = "1:50";
      }
      else {
        axisratio = "15min"
      }
    }
    if (strVal.indexOf(':') >= 0) {
      return parseFloat(templateSrv.replace(axisratio).split(':')[1]);
    } else {
      if (strVal.indexOf('h') >= 0) {
        return parseFloat(strVal.replace('h', '')) * 3600 * 1000;
      } else if (strVal.indexOf('min') >= 0) {
        //   return parseFloat(strVal.replace('min', '')) * 60 * 1000;
        return parseFloat(strVal.replace('min', '')) * 60 * 1000;
      }
      return;
    }

  }
  //#endregion
}
