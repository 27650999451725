import { dateTimeFormat, GrafanaTheme2 } from '@grafana/data';
// import { EChartsOption, CustomSeriesOption } from 'echarts';

// let prevIndex = 0;
// let counter = 0;
export class ActivitySeriesView {
  theme: GrafanaTheme2;
  palette: string[];
  constructor(theme: GrafanaTheme2) {
    this.theme = theme;
    this.palette = [
      theme.colors.text.primary,
      '#5470c6',
      '#91cc75',
      '#fac858',
      '#ee6666',
      '#73c0de',
      '#3ba272',
      '#fc8452',
      '#9a60b4',
      '#ea7ccc',
      'Brown',
      'MidnightBlue',
      'Cyan',
      'Green',
      'Red',
      'Purple',
      'DeepPink',
      'Yellow',
      'Blue',
      'Orange',
    ];
  }
  activityDict: string[] = [];
  getColorPalette(activityName: string) {
    if (this.activityDict.indexOf(activityName) < 0) {
      this.activityDict.push(activityName);
    }
    let palette = this.palette;
    return palette[this.activityDict.indexOf(activityName) % (palette.length - 1)];
  }
  renderActivityItem(params: any, api: any, fontSize: number | undefined) {
    var currentIndex = api.value!(0);
    var activity = api.value!(1);
    return {
      type: 'text',
      position: api.coord!([0, currentIndex]),
      style: {
        text: dateTimeFormat(currentIndex, {format:"MM-DD HH:mm:ss"}) + ': ' + activity,
        fill: this.getColorPalette(activity),
        fontSize: fontSize ?? 16,
      },
    };
  }
}
